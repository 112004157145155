<template>
  <div class="text-left">
    <br><br>
    <el-form label-width="120px">
      <el-form-item label="最新开奖结果">
        <el-select v-model="issue_result.first" placeholder="第一项">
          <el-option
            v-for="item in lotterList"
            :key="item.value"
            :label="item.label"
            :value="item.value" />
        </el-select>
        <br>
        <el-select style="margin: 10px 0;" v-model="issue_result.second" placeholder="第二项">
          <el-option
            v-for="item in lotterList"
            :key="item.value"
            :label="item.label"
            :value="item.value" />
        </el-select>
        <br>
        <el-select v-model="issue_result.third" placeholder="第三项">
          <el-option
            v-for="item in lotterList"
            :key="item.value"
            :label="item.label"
            :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="saveLoading"
          @click="handleSaveData">
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { settingIssueResult, settingLotterList, settingGet } from '@/api/admin/setting'

export default {
  data () {
    return {
      saveLoading: false,
      lotterList: [],
      issue_result: {
        first: null,
        second: null,
        third: null
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      settingGet().then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.issue_result = res.data.issue_result
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
      settingLotterList().then(res => {
        switch (res.status) {
          case resStatus.OK:
            var newArr = res.data.map(res => {
              return { value: res.lotterycode, label: res.lotteryname }
            })
            this.lotterList = newArr
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleSaveData () {
      this.saveLoading = true
      settingIssueResult(this.issue_result).then(res => {
        this.saveLoading = false
        switch (res.status) {
          case resStatus.OK:
            this.$message.success('保存成功')
            break
        }
        if (res.message.length !== 0) {
          this.$message(res.message)
        }
      }).catch(err => {
        this.saveLoading = false
        this.$message.error(err)
      })
    }
  }
}
</script>
